
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';

import { DOMAIN, isDevelopment, addZero } from '../../utils';
import { FURNITURE_STYLES } from '../../data';
import defaultViews from '../../test/default/views.json';
import defaultFloorPlan from '../../test/default/apartment-layout.png';

import { FurnitureStyles } from '../furniture-styles/furniture-styles';
import { SelectCamera } from '../select-camera';
import { Preloader } from '../preloader';
import { BeforeAfterSlider } from '../before-after-slider/before-after-slider';
import { Whitebox } from '../whitebox';
import { FloorPlan } from '../floor-plan';
import { Instruction } from '../instruction';
import { Loader } from '../loader';

export const App = () => {
    const [imageIndex, setImageIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [floorPlan, setFloorPlan] = useState(null);
    const [isShowInstruction, setIsShowInstruction] = useState(true);
    const [whitebox, setWhitebox] = useState(null);
    const [views, setViews] = useState(null);
    const [view, setView] = useState(null);
    const [aiFurnitureStyle, setAiFurnitureStyle] = useState(FURNITURE_STYLES[0].type);
    const [tab, setTab] = useState('floor-plan');
    const [aiResults, setAiResults] = useState(null);
    const [resultImage, setResultImage] = useState(null);
    const [imagesCount, setImagesCount] = useState(null);
    const [sliderValue, setSliderValue] = useState(50);

    const [cookies, setCookie] = useCookies(['isShowedInstruction'])
    const urlParams = new URLSearchParams(window.location.search);
    const flatEndpointUrl = urlParams.get('flat_endpoint');

    const setCurrentWhitebox = async () => {
        if (!view) return;

        let whitebox;

        setIsLoading(true);
        setAiResults(null);
        setResultImage(null);

        switch (true) {
            case (!!flatEndpointUrl && !isDevelopment): {
                try {
                    const currentWhiteboxRequest = await fetch(`${DOMAIN + flatEndpointUrl}/views/${(view.id).toLowerCase()}/whitebox.jpg`)
                    const data = await currentWhiteboxRequest.blob();
                    const reader = new FileReader();

                    reader.onloadend = function () {
                        setWhitebox(reader.result);
                    }

                    reader.readAsDataURL(data);
                } catch (error) {
                    console.log('Error:', error)
                }

                break;
            }
            case (!!flatEndpointUrl): {
                try {
                    const whiteboxResponse = await import(`../../test/${flatEndpointUrl}/views/${view.id}/whitebox.jpg`);
                    whitebox = whiteboxResponse.default;
                } catch (error) {
                    console.log('Error', error);
                }

                break;
            }
            default: {
                try {
                    const whiteboxResponse = await import(`../../test/default/views/${view.id}/whitebox.jpg`);
                    whitebox = whiteboxResponse.default;
                } catch (error) {
                    console.log('Error', error);
                }

                break;
            }
        }

        setWhitebox(whitebox);
        setCurrentAiResults();
    }

    const setCurrentAiResults = async () => {
        if (!aiFurnitureStyle) return;

        let currentAiResults = [];
        if (!isLoading) setIsLoading(true);

        setResultImage(null);

        switch (true) {
            case (!!flatEndpointUrl && !isDevelopment): {
                for (let i = 1; i <= imagesCount; i++) {
                    try {
                        const imageNumber = addZero(i);
                        const aiResult = `${DOMAIN + flatEndpointUrl}/views/${(view.id).toLowerCase()}/${aiFurnitureStyle}/${imageNumber}.jpg`;

                        await fetch(aiResult);

                        currentAiResults.push(aiResult);
                    } catch (error) {
                        console.log('Error:', error)
                    }
                }

                break;
            }
            case (!!flatEndpointUrl): {
                for (let i = 1; i <= imagesCount; i++) {
                    try {
                        const imageNumber = addZero(i);
                        const aiResultRequest = await import(`../../test/${flatEndpointUrl}/views/${(view.id).toLowerCase()}/${aiFurnitureStyle}/${imageNumber}.jpg`);
                        currentAiResults.push(aiResultRequest.default);
                    } catch (error) {
                        console.log('Error:', error)
                    }
                }

                break;
            }
            default: {
                for (let i = 1; i <= imagesCount; i++) {
                    try {
                        const imageNumber = addZero(i);
                        const aiResultRequest = await import(`../../test/default/views/${(view.id).toLowerCase()}/${aiFurnitureStyle}/${imageNumber}.jpg`);

                        currentAiResults.push(aiResultRequest.default);
                    } catch (error) {
                        console.log('Error:', error)
                    }
                }
            }
        }

        setAiResults(currentAiResults);
        setResultImage(currentAiResults[imageIndex]);

        setTimeout(() => {
            setIsLoading(false);
        }, 1000)
    }

    const handleTabClick = (e) => {
        const { target } = e;
        const tabValue = target.dataset.tab;

        setTab(tabValue);
    }

    const getViews = async () => {
        let views;

        switch (true) {
            case (!!flatEndpointUrl && !isDevelopment): {
                try {
                    const response = await fetch(`${DOMAIN + flatEndpointUrl}/views.json`);
                    const data = await response.json();

                    views = data;
                } catch (error) {
                    console.log('Error', error);
                }

                break;
            }
            case (!!flatEndpointUrl): {
                try {
                    const viewsResponse = await import(`../../test/${flatEndpointUrl}/views.json`);
                    views = viewsResponse.default;
                } catch (error) {
                    console.log('Error', error);
                }

                break;
            }
            default: {
                views = defaultViews;

                break;
            }
        }

        setViews(views)
    }

    const getFloorPlan = async () => {
        let floorPlan;

        switch (true) {
            case (!!flatEndpointUrl && !isDevelopment): {
                floorPlan = `${DOMAIN + flatEndpointUrl}/apartment-layout.png`;

                break;
            }
            case (!!flatEndpointUrl): {
                try {
                    const floorPlanResponse = await import(`../../test/${flatEndpointUrl}/apartment-layout.png`);
                    floorPlan = floorPlanResponse.default;
                } catch (error) {
                    console.log('Error', error);
                }

                break;
            }
            default: {
                floorPlan = defaultFloorPlan;

                break;
            }
        }

        setFloorPlan(floorPlan)
    }

    const getImagesCount = async () => {
        let imagesCount = 4;

        switch (true) {
            case (!!flatEndpointUrl && !isDevelopment): {
                try {
                    const response = await fetch(`${DOMAIN + flatEndpointUrl}/settings.json`);
                    const data = await response.json();

                    imagesCount = data.imagesCount;
                } catch (error) {
                    console.log('Error', error);
                }

                break;
            }
            case (!!flatEndpointUrl): {
                try {
                    const settingsResponse = await import(`../../test/${flatEndpointUrl}/settings.json`);

                    imagesCount = settingsResponse.default.imagesCount;
                } catch (error) {
                    console.log('Error', error);
                }

                break;
            }
            default: {
                imagesCount = 4;

                break;
            }
        }

        setImagesCount(imagesCount);
    }

    const incrementImageIndex = () => {
        if (!imagesCount) return;

        let nextImageindex = imageIndex + 1;

        if (nextImageindex >= imagesCount) {
            nextImageindex = 0;
        }

        setImageIndex(nextImageindex);
    }

    useEffect(() => {
        getImagesCount();
        getViews();
        getFloorPlan();
    }, [])

    useEffect(() => {
        incrementImageIndex();
        setCurrentWhitebox();
    }, [view])

    useEffect(() => {
        setCurrentAiResults();
    }, [aiFurnitureStyle])

    return (
        <CookiesProvider>
            <Preloader />
            {!cookies.isShowedInstruction && isShowInstruction && <Instruction setIsShowInstruction={setIsShowInstruction} setCookie={setCookie} />}
            <div className='ai-renders'>
                <div className={classNames('ai-renders__panel', { 'is-lock': isLoading })}>
                    <div className="ai-renders__buttons">
                        <button
                            type='button'
                            data-tab="floor-plan"
                            onClick={handleTabClick}
                            className={classNames('ai-renders__button', 'button', { 'is-active': tab === 'floor-plan' })}
                        >
                            Floor plan
                        </button>
                        <button
                            disabled={!whitebox}
                            type='button'
                            data-tab="styles"
                            onClick={handleTabClick}
                            className={classNames('ai-renders__button', 'button', { 'is-active': tab === 'styles' })}
                        >
                            Style
                        </button>
                    </div>
                    {tab === 'floor-plan' && <FloorPlan view={view} setView={setView} floorPlan={floorPlan} views={views} />}
                    {tab === 'styles' && (
                        <FurnitureStyles
                            imagesCount={imagesCount}
                            aiFurnitureStyle={aiFurnitureStyle}
                            setAiFurnitureStyle={setAiFurnitureStyle}
                            setIsLoading={setIsLoading}
                            aiResults={aiResults}
                            resultImage={resultImage}
                            setResultImage={setResultImage}
                            isLoading={isLoading}
                            incrementImageIndex={incrementImageIndex}
                            imageIndex={imageIndex}
                        />
                    )}
                </div>
                <div className="ai-renders__body">
                    {!whitebox && <SelectCamera />}
                    {whitebox && (!resultImage || isLoading) && <Whitebox whitebox={whitebox} />}
                    {isLoading && <Loader />}
                    {resultImage && !isLoading && <BeforeAfterSlider whitebox={whitebox} resultImage={resultImage} sliderValue={sliderValue} setSliderValue={setSliderValue} />}
                </div>
            </div>
        </CookiesProvider>
    )
};
