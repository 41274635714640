import { useState, useEffect } from "react";

export const Loader = () => {
    const [percents, setPercents] = useState(0);

    const loadingSeconds = 8;

    useEffect(() => {
        const loadingInterval = setInterval(() => {
            setPercents(prev => {
                if (prev === 100) {
                    clearInterval(loadingInterval);

                    return prev;
                } else {
                    return prev + 1;
                }
            });
        }, 100 / loadingSeconds);

        return () => {
            clearInterval(loadingInterval);
        }
    }, [])

    return (
        <div className="loader">
            <div className="loader__progress" style={{ width: `${percents}%` }}></div>
        </div>
    )
}