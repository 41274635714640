import firstAction from '../assets/images/instruction/01.png';
import secondAction from '../assets/images/instruction/02.png';
import thirdAction from '../assets/images/instruction/03.png';

export const instructionSteps = [
    {
        index: 0,
        title: "Floor plan",
        text: "Choose a camera",
        action: firstAction,
    },
    {
        index: 1,
        title: "Style",
        text: "Choose your desired interior style",
        action: secondAction,
    },
    {
        index: 2,
        title: "Before/After",
        text: "Compare the result before/after",
        action: thirdAction,
    },
]