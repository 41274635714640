import minimalismImage from '../assets/images/styles/minimalism.jpg';
import loftImage from '../assets/images/styles/loft.jpg';
import scandinavianImage from '../assets/images/styles/scandinavian.jpg';
import neoclassicalImage from '../assets/images/styles/neoclassical.jpg';

export const FURNITURE_STYLES = [
    {
        name: "Minimalism",
        type: "minimalist",
        isAvailable: true,
        image: minimalismImage
    },
    {
        name: "Loft",
        type: "loft",
        isAvailable: true,
        image: loftImage
    },
    {
        name: "Scandinavian",
        type: "scandinavian",
        isAvailable: true,
        image: scandinavianImage
    },
    {
        name: "Neoclassical",
        type: "classical",
        isAvailable: true,
        image: neoclassicalImage
    }
];
