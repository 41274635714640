import classNames from 'classnames';
import { useImageSize } from 'react-image-size';

export const FloorPlan = ({ view, setView, floorPlan, views }) => {
    const [dimensions] = useImageSize(floorPlan);

    const handleViewClick = (e) => {
        const { target } = e;
        const view = views?.find(viewItem => viewItem.id === target.dataset.view);

        if (view) setView(view);
    }

    const getViewStyles = (view) => {

        return {
            transform: `rotate(${view?.rotate}deg)`,
            top: view.top / dimensions?.height * 100 + '%',
            left: view.left / dimensions?.width * 100 + '%',
            aspectRatio: view.width && view.height ? view.width / view.height : "unset",
        };
    }

    return (
        <div className="floor-plan">
            <div className="floor-plan__body">
                <div style={{ aspectRatio: `${dimensions?.width}/${dimensions?.height}` }} className="floor-plan__image">
                    <img src={floorPlan} alt="Floor plan" title='Floor plan' />
                    {
                        views && dimensions && (
                            views.map((viewItem, i) => (
                                <button
                                    type='button'
                                    key={i}
                                    data-view={viewItem.id}
                                    style={getViewStyles(viewItem)}
                                    onClick={handleViewClick}
                                    className={classNames('floor-plan__view', { "is-active": viewItem.id === view?.id })}
                                >
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 13.3335C8 11.1244 9.79086 9.3335 12 9.3335H20C22.2091 9.3335 24 11.1244 24 13.3335V25.3335C24 27.5426 22.2091 29.3335 20 29.3335H12C9.79086 29.3335 8 27.5426 8 25.3335L8 13.3335Z" fill="currentColor" />
                                        <path d="M17.1926 13.615C16.7012 14.5977 15.2988 14.5977 14.8074 13.615L10.2982 4.59643C9.85489 3.70989 10.4996 2.66681 11.4907 2.66681L20.5093 2.66681C21.5005 2.66681 22.1451 3.70989 21.7019 4.59643L17.1926 13.615Z" fill="currentColor" />
                                    </svg>
                                    <div className="floor-plan__view-light">
                                        <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.5925 24.3991C11.0557 25.867 8.77749 25.867 8.24062 24.3991L0.0919286 2.11923C-0.289386 1.07665 0.562073 -2.83021e-07 1.7679 -1.72056e-07L18.0653 1.32769e-06C19.2711 1.43866e-06 20.1226 1.07666 19.7412 2.11923L11.5925 24.3991Z" fill="#FF7308" fillOpacity="0.5" />
                                        </svg>
                                    </div>
                                </button>
                            ))
                        )
                    }
                </div>
            </div>
            <div className="floor-plan__bottom">
                <p className="floor-plan__bottom-item">
                    Camera: <span>{view?.name}</span>
                </p>
                {/* <p className="floor-plan__bottom-item">
                    Size: 100%
                </p> */}
            </div>
        </div>
    )
}