import { useEffect, useState } from 'react';
import classNames from 'classnames';

import logo from '../../assets/images/logo.svg';

export const Preloader = () => {
    const [percents, setPercents] = useState(0);

    const loadingSeconds = 10;

    useEffect(() => {
        const loadingInterval = setInterval(() => {
            setPercents(prev => {
                if (prev === 100) {
                    clearInterval(loadingInterval);

                    return prev;
                } else {
                    return prev + 1;
                }
            });
        }, 100 / loadingSeconds);

        return () => {
            clearInterval(loadingInterval);
        }
    }, [])

    return (
        <div className={classNames("preloader", { 'is-hidden': percents === 100 })}>
            <div className="preloader__body">
                <div className="preloader__logo">
                    <img src={logo} alt="Logo" title='Logo' />
                </div>
                <div className="preloader__app"><span>AI</span> Renders</div>
                <div className="preloader__progressbar">
                    <div className="preloader__progressbar-progress" style={{ width: `${percents}%` }}></div>
                </div>
            </div>
        </div>
    )
}