import { useState } from 'react';
import { ComparisonSlider } from 'react-comparison-slider';

import { APP_DOMAIN, isDevelopment } from '../../utils';

export const BeforeAfterSlider = ({ whitebox, resultImage, sliderValue, setSliderValue }) => {
    const [isDesktop, setIsDesktop] = useState(window.matchMedia('(min-width: 1200px)').matches);
    const sharingUrl = isDevelopment ? APP_DOMAIN + resultImage : resultImage;
    const shareData = {
        title: "Ai renders",
        text: "Enjoy generation!",
        url: sharingUrl,
    };

    const handleResizeWindow = () => {
        setIsDesktop(window.matchMedia('(min-width: 1200px)').matches);
    }

    const handleSliderChange = (value) => {
        setSliderValue(value);
    }

    const handleSharingClick = async () => {
        try {
            await navigator.share(shareData);

        } catch (error) {
            console.log(error);
        }
    }

    window.addEventListener('resize', handleResizeWindow);

    return (
        <div className='before-after-slider'>
            <ComparisonSlider
                value={sliderValue}
                onValueChange={handleSliderChange}
                itemOne={<img src={whitebox} alt="Image" title='Image' />}
                itemTwo={<img src={resultImage} alt="Image" title='Image' />}
                aspectRatio={1290 / 1080}
                handle={({ isFocused }) => {
                    return (
                        <div className='before-after-slider__handler'></div>
                    );
                }}
            />
            {isDesktop && (
                <a href={resultImage} download='ai-render' className='before-after-slider__share'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 21H18M12 3V17M12 17L17 12M12 17L7 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </a>
            )}
            {!isDesktop && (
                <button type='button' onClick={handleSharingClick} className='before-after-slider__share'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5 5H14.8333C13.4332 5 12.7331 5 12.1984 5.27248C11.728 5.51217 11.3455 5.89462 11.1058 6.36502C10.8333 6.8998 10.8333 7.59987 10.8333 9V10M17.5 5L15 2.5M17.5 5L15 7.5M8.33333 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V11.6667" stroke="currentColor" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            )}
        </div>
    )
}