import classNames from 'classnames';
import { useState } from 'react';

import { FURNITURE_STYLES } from '../../data';

import refreshIcon from '../../assets/images/icons/refresh.svg';

export const FurnitureStyles = ({ setAiFurnitureStyle, aiFurnitureStyle, setIsLoading, aiResults, resultImage, setResultImage, imagesCount, incrementImageIndex, imageIndex }) => {
    const [isAnimationRefresh, setIsAnimationRefresh] = useState(false);

    const handleStyleClick = (e) => {
        const { target } = e;

        if (target.classList.contains('furniture-styles__item-refresh')) return;

        const targetParent = target.closest('.furniture-styles__item');

        if (!targetParent) return;

        const styleType = targetParent.dataset.type;

        setAiFurnitureStyle(styleType);
    }

    const handleRefreshClick = () => {
        setIsLoading(true);
        setIsAnimationRefresh(true);

        const nextResultImage = imageIndex + 1 >= imagesCount ? aiResults[0] : aiResults[imageIndex + 1];

        incrementImageIndex();

        setTimeout(() => {
            setIsLoading(false);
            setIsAnimationRefresh(false);
            setResultImage(nextResultImage);
        }, 1000);
    }

    return (
        <div className='furniture-styles'>
            {FURNITURE_STYLES && (
                <ul className="furniture-styles__list">
                    {FURNITURE_STYLES.map((style, i) => (
                        <li
                            key={i}
                            data-type={style.type}
                            onClick={handleStyleClick}
                            className={
                                classNames('furniture-styles__item', { 'is-active': aiFurnitureStyle === style.type })
                            }
                        >
                            <div className="furniture-styles__item-image">
                                <img src={style.image} alt={style.name} title={style.name} />
                                <button type='button' onClick={handleRefreshClick} className={classNames('furniture-styles__item-refresh', { 'is-animate': isAnimationRefresh })}>
                                    <img src={refreshIcon} alt="Refresh" title='Refresh' />
                                </button>
                            </div>
                            <span className="furniture-styles__item-name">{style.name}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}