import { useState } from 'react';
import classNames from 'classnames';

import { instructionSteps } from '../../data';

export const Instruction = ({ setIsShowInstruction, setCookie }) => {
    const [step, setStep] = useState(instructionSteps[0]);
    const [previousSteps, setPreviousSteps] = useState([instructionSteps[0]]);

    const handleCloseClick = () => {
        setIsShowInstruction(false);
    }

    const handleStepClick = (e) => {
        const { target } = e;
        const stepIndex = target.dataset.step;
        const previousSteps = instructionSteps.filter(step => step.index <= stepIndex);

        setStep(instructionSteps[stepIndex]);
        setPreviousSteps(previousSteps);
    }

    const handleButtonClick = () => {
        const currentStepIndex = step.index;
        const nextStep = instructionSteps[currentStepIndex + 1];

        if (nextStep) {
            setStep(nextStep);
            setPreviousSteps(prev => [...prev, nextStep]);
        } else {
            const nextDay = new Date(Date.now() + 24 * 60 * 60 * 1000);

            setIsShowInstruction(false);
            setCookie('isShowedInstruction', true, { expires: nextDay });
        }
    }

    return (
        <div className="instruction">
            <div onClick={handleCloseClick} className="instruction__backdrop"></div>
            <div className="instruction__body">
                <header className="instruction__header">
                    <h2 className="instruction__title">{step.title}</h2>
                    <button onClick={handleCloseClick} type="button" className="instruction__close"></button>
                </header>
                <p className="instruction__text">{step.text}</p>
                <div className="instruction__action">
                    <img src={step.action} alt={step.title} title={step.title} />
                </div>
                <ul className="instruction__steps">
                    {previousSteps && (
                        <>
                            {previousSteps.map((stepItem, i) => (
                                <li key={i} className="instruction__step">
                                    <button
                                        className={classNames("instruction__step-button", { 'is-current': stepItem.index === step.index })}
                                        data-step={stepItem.index}
                                        onClick={handleStepClick}
                                    >
                                        Step {stepItem.index + 1}
                                    </button>
                                </li>
                            ))}
                        </>
                    )}
                </ul>
                <button type="button" onClick={handleButtonClick} className="instruction__button button">Next</button>
            </div>
        </div>
    )
}